import React, { ReactNode } from "react";
import styled from "styled-components";
import { badgeTypes } from "./BadgeTypes";

type BadgeType = keyof typeof badgeTypes;

interface BadgeProps {
  type?: BadgeType;
  label: ReactNode;
  className?: string;
}

export const Badge = ({ type = "success", label, className }: BadgeProps) => {
  return (
    <StyledBadge type={type} className={className}>
      {label}
    </StyledBadge>
  );
};

const StyledBadge = styled.div<{
  type: BadgeType;
}>`
  white-space: nowrap;
  width: fit-content;
  background-color: ${({ type }) => badgeTypes[type].background};
  border: ${({ type }) => `1px solid ${badgeTypes[type].border}`};
  border-radius: 6px;
  font-size: 12px;
  padding: 2px 5px;
  color: ${({ type }) => badgeTypes[type].colorText};
  box-shadow: ${({ theme }) => theme.shadow.small};
  * {
    color: ${({ type }) => badgeTypes[type].colorText};
  }
`;

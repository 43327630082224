import styled, { css } from "styled-components";
import { ProductPlaceHolder } from "@/assets/Icons/ProductPlaceHolder";
import { Image } from "@/generated/graphql";
import { Photo } from "./Photo";
import { themeColor } from "../../styles-utils";
import { CSSProperties } from "react";

type SizeObj = { width: number; height: number };

interface ItemImgProps {
  imgDetails: Image | undefined | null;
  size?: number | SizeObj;
  innerSize?: CSSProperties["width"];
  objectFit?: CSSProperties["objectFit"];
  onlyImg?: boolean;
  maxHeight?: CSSProperties["maxHeight"];
  isSquare?: boolean;
}

const ItemImg = ({
  imgDetails,
  size,
  innerSize,
  objectFit = "contain",
  onlyImg,
  maxHeight,
  isSquare = true,
}: ItemImgProps) => {
  return (
    <ImgContainer
      data-test="item-image"
      size={size}
      onlyImg={onlyImg}
      maxHeight={maxHeight}
      isSquare={isSquare}
    >
      {!imgDetails?.src ? (
        <ProductPlaceHolder
          width={innerSize ? innerSize : ""}
          height={innerSize ? innerSize : ""}
        />
      ) : (
        <Photo
          src={imgDetails?.src}
          alt={imgDetails?.altText!}
          objectFit={objectFit}
          width={innerSize ? innerSize : ""}
          height={innerSize ? innerSize : ""}
        />
      )}
    </ImgContainer>
  );
};

export default ItemImg;

const ImgContainer = styled.div<{
  size: ItemImgProps["size"];
  onlyImg: ItemImgProps["onlyImg"];
  maxHeight: ItemImgProps["maxHeight"];
  isSquare: ItemImgProps["isSquare"];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  aspect-ratio: ${({ isSquare }) => (isSquare ? "1/1" : "unset")};
  ${({ onlyImg, size }) => {
    const isObject = !!(size as SizeObj)?.width;
    // for casting the type SizeObj
    const sizes = isObject ? (size as SizeObj) : undefined;
    return onlyImg
      ? css`
          width: ${size
            ? isObject
              ? `${sizes?.width}px`
              : `${size}px`
            : "100%"};
          height: ${size
            ? isObject
              ? `${sizes?.height}px`
              : `${size}px`
            : "100%"};

          &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        `
      : css`
          width: ${size
            ? isObject
              ? `${sizes?.width}px`
              : `${size}px`
            : "72px"};
          height: ${size
            ? isObject
              ? `${sizes?.height}px`
              : `${size}px`
            : "72px"};

          background-color: ${themeColor("white")};
          border: 1px solid ${({ theme }) => theme.bg.inactive};
          border-radius: 4px;
          > svg,
          > img {
            border-radius: 3px;
          }
        `;
  }}
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "unset")};
`;

import { theme } from "../../../../../tailwind.config";

export type ButtonRequiredColorsType = {
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
};

export type ButtonColorsForStatusType = {
  default: ButtonRequiredColorsType;
  disabled: ButtonRequiredColorsType;
  hover: ButtonRequiredColorsType;
};

export type ButtonTypes = "primary" | "secondary" | "danger";

export const getButtonColorsOnType = ({
  storePrimaryColor,
}: {
  storePrimaryColor: string;
}): {
  [key in ButtonTypes]: ButtonColorsForStatusType;
} => ({
  primary: {
    default: {
      backgroundColor: storePrimaryColor,
      borderColor: storePrimaryColor,
      color: "white",
    },
    hover: {
      backgroundColor: darkenHex(storePrimaryColor, 0.1),
      borderColor: darkenHex(storePrimaryColor, 0.1),
      color: "white",
    },
    disabled: {
      backgroundColor: storePrimaryColor,
      borderColor: storePrimaryColor,
      color: "white",
    },
  },
  secondary: {
    default: {
      backgroundColor: "white",
      borderColor: theme.extend.colors.gray[300],
      color: theme.extend.colors.gray[700],
    },
    hover: {
      backgroundColor: theme.extend.colors.gray[100],
      borderColor: theme.extend.colors.gray[300],
      color: theme.extend.colors.gray[700],
    },
    disabled: {
      backgroundColor: "white",
      borderColor: theme.extend.colors.gray[300],
      color: theme.extend.colors.gray[300],
    },
  },
  danger: {
    default: {
      backgroundColor: theme.extend.colors.error[600],
      borderColor: theme.extend.colors.error[600],
      color: "white",
    },
    hover: {
      backgroundColor: theme.extend.colors.error[700],
      borderColor: theme.extend.colors.error[700],
      color: "white",
    },
    disabled: {
      backgroundColor: theme.extend.colors.error[200],
      borderColor: theme.extend.colors.error[200],
      color: "white",
    },
  },
});

export const ButtonSizes = {
  xs: {
    px: 14,
    py: 8,
  },
  sm: {
    px: 16,
    py: 10,
  },
  md: {
    px: 18,
    py: 10,
  },
  lg: {
    px: 20,
    py: 12,
  },
  xl: {
    px: 28,
    py: 16,
  },
};

export const OnlyIconButtonSizes = {
  xs: {
    px: 8,
    py: 8,
  },
  sm: {
    px: 10,
    py: 10,
  },
  md: {
    px: 12,
    py: 12,
  },
  lg: {
    px: 14,
    py: 14,
  },
  xl: {
    px: 16,
    py: 16,
  },
};

export function darkenHex(hexColor: any, percentage = 0.1) {
  const color = hexColor?.toString()?.replace("#", "");
  const rgb = [
    parseInt(color.slice(0, 2), 16),
    parseInt(color.slice(2, 4), 16),
    parseInt(color.slice(4, 6), 16),
  ];

  const darkenedRgb = rgb?.map((channel) =>
    Math.round(channel * (1 - percentage))
  );

  const darkenedHex = `#${darkenedRgb
    .map((channel) => channel.toString(16).padStart(2, "0"))
    .join("")}`;

  return darkenedHex;
}

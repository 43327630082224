import { Maybe } from "graphql/jsutils/Maybe";
import { DiscountTranslation } from "../../generated/graphql";

type GetTranslatedDiscountTitleInput = {
  defaultTitle?: string | null;
  translations?: Maybe<Maybe<DiscountTranslation>[]> | undefined;
  currentLocale?: string | null;
};

export function getTranslatedDiscountTitle({
  defaultTitle,
  translations,
  currentLocale,
}: GetTranslatedDiscountTitleInput) {
  const translation = translations?.find(
    (translation) => translation?.locale === currentLocale
  );

  if (!translation) {
    return defaultTitle;
  }

  return translation.title;
}

import { theme } from "../../../theme";

export const badgeTypes = {
  white: {
    background: theme.bg.default,
    colorText: theme.text.default,
    border: theme.bg.border,
  },
  ink: {
    background: theme.bg.reverse,
    colorText: theme.text.default,
    border: theme.bg.border,
  },
  success: {
    background: theme.success.default,
    colorText: theme.success.wash,
    border: theme.success.border,
  },
  warn: {
    background: theme.warn.default,
    colorText: theme.warn.wash,
    border: theme.warn.border,
  },
  danger: {
    background: theme.danger.default,
    colorText: theme.danger.wash,
    border: theme.danger.border,
  },
};
